import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

function TipStandard({ value, standardTip, isCustom, onClickTip }) {
    return (
        <Button
            value={value}
            key={value}
            size="small"
            style={{ height: 40, width: 60, fontSize: '1em', borderWidth: 2 }}
            variant={
                !isCustom && standardTip === value ? 'contained' : 'outlined'
            }
            color="primary"
            onClick={() => onClickTip(value)}
        >
            {value}%
        </Button>
    );
}

TipStandard.propTypes = {};

export default TipStandard;
