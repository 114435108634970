/**
 * PAYLOAD ATTRIBUTES
 *  - checkinId
 *  - customerType
 *  - deliveryAddress
 *  - items: alias to orders
 *  - orderInstruction
 *  - orderType
 *  - orderUnderName
 *  - phoneNumber
 *  - restauId
 *  - scheduledDate
 *  - scheduledOrder: computed
 *  - scheduledTime
 *  - tableId
 *  - tip
 */

import { getTip } from '../Order.utils';
import encrypt from '../../Utils/encrypt';

const getPayload = ({
    checkinId,
    customerType,
    deliveryAddress,
    deliveryFees,
    isOrderAheadEnabled,
    orderInstruction,
    orders: items,
    orderType,
    orderUnderName,
    phoneNumber,
    restauId,
    scheduledDate,
    scheduledForLater,
    scheduledTime,
    tableId,
    tipPercent,
    getOrderSubtotal,
    cardNumber,
    expiry,
    cvc,
    zip,
    payAtStore,
    payNow,
    deliveryAPT,
    deliveryScheduledForLater,
    deliveryScheduledDate,
    deliveryScheduledTime,
}) => {
    const subtotal = getOrderSubtotal();
    const tip = getTip(subtotal, tipPercent);
    let paymentInfo = null;
    if (cardNumber && expiry && cvc) {
        paymentInfo = {
            cardNumber: cardNumber,
            expiry: expiry,
            cvc: cvc,
            zip: zip,
        };
        paymentInfo = encrypt(JSON.stringify(paymentInfo));
    }
    let scheduledOrder =
        isOrderAheadEnabled && scheduledForLater ? true : false;
    if (orderType === 'Delivery') {
        scheduledOrder = false;
        scheduledDate = null;
        scheduledTime = null;
    }
    const payload = {
        checkinId,
        customerType,
        items,
        orderInstruction,
        orderType,
        orderUnderName,
        phoneNumber,
        restauId,
        scheduledDate,
        scheduledOrder: scheduledOrder,
        scheduledTime,
        tip,
        payAtStore,
        payNow,
        deliveryScheduledForLater,
        deliveryScheduledDate,
        deliveryScheduledTime,
    };
    if (tableId) {
        payload.tableId = tableId;
    }
    if (deliveryAPT && deliveryAddress) {
        let dparts = deliveryAddress.split(',');
        if (dparts.length >= 3) {
            // dparts = [ '1060 Market Drive', ' Vernal', ' UT', ' USA' ]
            // ' Suite/Apt: #623'
            dparts.splice(dparts.length - 3, 0, ' Suite/Apt: #' + deliveryAPT);
            payload.deliveryAddress = dparts.join(',');
        } else {
            payload.deliveryAddress =
                'Suite/Apt: #' + deliveryAPT + ', ' + deliveryAddress;
        }
    } else if (deliveryAddress) {
        payload.deliveryAddress = deliveryAddress;
    }
    if (deliveryFees) {
        payload.deliveryFees = deliveryFees;
    }
    if (paymentInfo) {
        payload.paymentInfo = paymentInfo;
    }
    debugger;
    if (
        window.location.hostname == 'strideq.com' ||
        window.location.hostname == 'www.strideq.com' ||
        window.location.hostname == 'localhost'
    ) {
        payload.legacyDomain = true;
    }
    return payload;
};

export default getPayload;
