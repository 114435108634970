import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@material-ui/core/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import IconClose from '@mui/icons-material/Close';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import theme from './theme';
import AppRoutes from './AppRoutes';

function SnackbarCloseButton({ id }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(id)}>
            <IconClose />
        </IconButton>
    );
}

function App() {
    // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // const openFood = useOpenFood();
    // const showOrders = useShowOrders();
    //const ordersHook = useOrders();
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <SnackbarProvider
                    dense
                    preventDuplicate
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    action={(key) => <SnackbarCloseButton key={key} id={key} />}
                    disableWindowBlurListener={true}
                    hideIconVariant={true}
                >
                    <ErrorBoundary>
                        <AppRoutes />
                    </ErrorBoundary>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
export default App;
