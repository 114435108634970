import React, { useContext, useState } from 'react';
import { GlobalStyle } from './Styles/GlobalStyle';
import { FoodDialog } from './FoodDialog/FoodDialog';
import { Navbar } from './Navbar/Navbar';
import { OrderContext, OrderProvider } from './context/orderContext';
import { Banner } from './Banner/Banner';
import MenuWrapper from './Menu/MenuWrapper';
import { ViewCart } from './Order/ViewCart';
import { useOpenFood } from './Hooks/useOpenFood';
import { useShowOrders } from './Hooks/useShowOrders';
import { useOrders } from './Hooks/useOrders';
import { useSnackbar } from 'notistack';
import { useAuthentication } from './Hooks/useAuthentication';
import { useTitle } from './Hooks/useTitle';
import Cart from './Order/Cart';

export default function StoreRoot() {
    // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // const openFood = useOpenFood();
    // const showOrders = useShowOrders();
    // const orders = useOrders(
    //     enqueueSnackbar,
    //     closeSnackbar,
    //     showOrders,
    //     openFood
    // );

    const orders = useContext(OrderContext);
    const { openFood, showOrders } = orders;

    const auth = useAuthentication();

    const [searchMobileActive, setSearchMobileActive] = useState(false);

    useTitle({ ...openFood, ...orders });

    return (
        <>
            <GlobalStyle />
            <FoodDialog {...openFood} {...orders} />
            <Navbar
                {...auth}
                {...orders}
                {...showOrders}
                {...openFood}
                key="navBar"
            />
            {/* {showOrders.showOrders && (
                            <Order {...orders} {...openFood} {...auth} {...showOrders} />
                        )} */}
            {/* <OrderProvider value={orders}> */}
            {showOrders.showOrders && <Cart {...auth} {...showOrders} />}
            {!searchMobileActive && (
                <Banner
                    img={orders.bannerUrl}
                    imgDesktop={orders.bannerUrlDesktop}
                    bannerImageParts={orders.bannerImageParts}
                />
            )}

            <MenuWrapper
                {...openFood}
                {...orders}
                {...{
                    searchMobileActive,
                    setSearchMobileActive,
                }}
                key="menuWrapper"
            />
            <ViewCart {...orders} {...showOrders} />
            {/* </OrderProvider> */}
        </>
    );
}
