import { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

export function useToppings(defaultTopping) {
    const [toppings, setToppings] = useState(cloneDeep(defaultTopping));

    function checkTopping(index) {
        const newToppings = [...toppings];
        newToppings[index].checked = !newToppings[index].checked;
        setToppings(newToppings);
    }

    return {
        checkTopping,
        toppings,
    };
}

// const [toppings, setToppings] = useState(
//   defaultTopping || getDefaultToppings()
// );
// const toppingsList = [
//   "Extra Cheese",
//   "Pepperoni",
//   "Sausage",
//   "Onions",
//   "Peppers",
//   "Pineapple",
//   "Ham",
//   "Spinach",
//   "Artichokes",
//   "Mushrooms",
//   "Anchovies"
// ];

// function getDefaultToppings() {
//   return toppingsList.map(topping => ({
//     name: topping,
//     checked: false
//   }));
//}
