import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import Caption from './Caption';

function FoodItem(props) {
    const {
        quantity,
        title,
        choice,
        toppings,
        showStarRating,
        ratings,
        modifiers,
        notes,
        readOnly,
        price,
        onRatingChange,
        onEdit,
        onDelete,
    } = props;

    return (
        <Card style={{ padding: 4, marginBottom: 4 }}>
            <div style={{ display: 'flex', minHeight: 40 }}>
                <div style={{ width: 40, flexShrink: 0 }}>
                    <Typography>
                        {quantity}
                        <Typography variant="caption">&nbsp;x</Typography>
                    </Typography>
                </div>
                <div style={{ flexGrow: 1, padding: '0 10px' }}>
                    <Typography>{title}</Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {choice && <Caption>{choice}</Caption>}
                        {toppings && <Caption>{toppings}</Caption>}
                        {modifiers && <Caption>{modifiers}</Caption>}
                        {notes && (
                            <Caption>
                                <i className="fas fa-info-circle"></i> {notes}
                            </Caption>
                        )}
                    </div>
                    <div style={{ marginLeft: -20 }}>
                        {onEdit && (
                            <Button
                                color="primary"
                                size="small"
                                onClick={onEdit}
                            >
                                Edit
                            </Button>
                        )}
                        {onDelete && (
                            <Button
                                color="primary"
                                size="small"
                                onClick={onDelete}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </div>
                <div style={{ margin: '0 4px' }}>
                    {showStarRating && (
                        <Rating
                            name={title}
                            defaultValue={ratings}
                            precision={0.5}
                            readOnly={readOnly}
                            size="small"
                            onChange={onRatingChange}
                        />
                    )}
                </div>
                <div>
                    <Typography>{price}</Typography>
                </div>
            </div>
        </Card>
    );
}

FoodItem.propTypes = {};

export default FoodItem;
