import React from 'react';
import PropTypes from 'prop-types';
import FoodTitle from './FoodTitle';
import FoodPrice from './FoodPrice';

import {
    FoodContainer,
    FoodLabelRight,
    ImageDetails,
    Title,
    Description,
} from './FoodItem.styles';

function FoodItemImageRight({ food, setOpenFood }) {
    return (
        <FoodContainer
            oos={food.oos}
            onClick={() => {
                food.oos
                    ? alert(food.name + ' is temporarily out of stock')
                    : setOpenFood(food);
            }}
        >
            <FoodLabelRight img={food.img}>
                <ImageDetails>
                    <Title>
                        <FoodTitle name={food.name} oos={food.oos} />
                    </Title>
                    <Description>{food.desc}</Description>
                    <div
                        style={{
                            paddingLeft: '10px',
                            paddingTop: '5px',
                            fontSize: '15px',
                        }}
                    >
                        <FoodPrice {...food} />
                    </div>
                </ImageDetails>

                {food.img && (
                    <img
                        src={food.img}
                        alt={food.name}
                        style={{
                            width: '94px',
                            height: '94px',
                            objectFit: 'cover',
                            borderRadius: '5%',
                        }}
                    />
                )}
            </FoodLabelRight>
        </FoodContainer>
    );
}

FoodItemImageRight.propTypes = {};

export default FoodItemImageRight;
