import React from 'react';
import PropTypes from 'prop-types';

import { FoodContainer2, FoodLabel2, Description2 } from './FoodItem.styles';
import FoodTitle from './FoodTitle';
import FoodPrice from './FoodPrice';

function FoodItem2({ food, setOpenFood }) {
    return (
        <FoodContainer2
            oos={food.oos}
            onClick={() => {
                food.oos
                    ? alert(food.name + ' is temporarily out of stock')
                    : setOpenFood(food);
            }}
        >
            <FoodLabel2>
                <div
                    style={{
                        marginRight: '20px',
                    }}
                >
                    <FoodTitle name={food.name} oos={food.oos} />
                </div>
                <div>
                    <FoodPrice {...food} />
                </div>
            </FoodLabel2>
            <Description2>{food.desc}</Description2>
        </FoodContainer2>
    );
}

FoodItem2.propTypes = {};

export default FoodItem2;
