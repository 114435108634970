import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import Config from '../config/Config';
import axios from 'axios';
import moment from 'moment-timezone';

const useForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { restauId, inquiryId } = useParams();
    const [formConfig, setFormConfig] = useState({});
    const [form, setForm] = useState({});
    const [formFields, setFormFields] = useState({});
    const [strippedInquiryData, setStrippedInquiryData] = useState({});
    const [errors, setErrors] = useState({});
    const [newFormToggle, setNewFormToggle] = useState(true);
    const [loading, setLoading] = useState(true);
    const [kitchenToggle, setKitchenToggle] = useState(
        /^\/catering\/[^/]+\/[^/]+\/kitchen$/.test(location.pathname)
            ? true
            : false
    );
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const source = params.get('s');
    var tempErrors = {};

    const extractFieldNames = (formConfig) => {
        let initialForm = {};
        formConfig.fields.forEach((field) => {
            initialForm[field.field_name] = '';
        });
        return initialForm;
    };

    const retrieveFormConfig = async (restauId, inquiryId) => {
        let reqBody = { restauId: restauId, inquiryId: inquiryId };
        try {
            const response = await axios.post(
                Config.get_catering_form_config,
                reqBody,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.data || response.data.status === false) {
                setSnackbarMessage(
                    'Issue getting catering form. Please contact support if the issue persists.'
                );
                setSnackbarOpen(true);
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            }

            setFormConfig(response.data.formConfig);
            setForm(extractFieldNames(response.data.formConfig));
            setFormFields(extractFieldNames(response.data.formConfig));
            setErrors(extractFieldNames(response.data.formConfig));
            if (
                response.data.inquiryData &&
                response.data.inquiryData[0].canEdit === false
            ) {
                setStrippedInquiryData(response.data.inquiryData[0]);
                if (!kitchenToggle)
                    navigate(`/catering/${restauId}/${inquiryId}/success`);
                else navigate(`/catering/${restauId}/${inquiryId}/kitchen`);
                return;
            }
            if (
                response.data.inquiryData &&
                response.data.inquiryData.length !== 0
            ) {
                setNewFormToggle(false);
                setForm(response.data.inquiryData[0]);
            }
        } catch (error) {
            console.log('Caught error in retrieveFormConfig: ', error);
            setSnackbarMessage(
                'Issue getting catering form. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        retrieveFormConfig(restauId, inquiryId);
    }, []);

    const updateForm = (value) => {
        return setForm((prev) => {
            return { ...prev, ...value };
        });
    };

    const validationFunctions = {
        required: (value, bool, field) => {
            if (bool && !value) {
                tempErrors[field.field_name] = 'This field is required';
                return !!value;
            } else return true;
        },
        maxLength: (value, maxLength, field) => {
            if (maxLength !== '' && value.length > maxLength) {
                tempErrors[
                    field.field_name
                ] = `Exceeding maximum of ${maxLength} characters`;
                return value.length <= maxLength;
            } else return true;
        },
        min_number: (value, minValue, field) => {
            if (!isNaN(Number(value)) && minValue !== '' && value < minValue) {
                tempErrors[
                    field.field_name
                ] = `Minimum of ${minValue} required`;
                return value >= minValue;
            } else return true;
        },
        max_number: (value, maxValue, field) => {
            if (!isNaN(Number(value)) && maxValue !== '' && value > maxValue) {
                tempErrors[
                    field.field_name
                ] = `Exceeding maximum of ${maxValue}`;
                return value <= maxValue;
            } else return true;
        },
        is_number: (value, bool, field) => {
            if (value !== '' && bool && isNaN(Number(value))) {
                tempErrors[field.field_name] = 'Please enter a valid number';
                return false;
            } else return true;
        },
        noEarlierThan: (value, otherFieldValue, field) => {
            if (
                value !== '' &&
                moment(value, 'h:mmA') <= moment(otherFieldValue, 'h:mmA')
            ) {
                tempErrors[
                    field.field_name
                ] = `Should be after ${field.field_label}`;
                return false;
            } else return true;
        },
        email: (value, bool, field) => {
            // regex for email validation
            const re = /\S+@\S+\.\S+/;
            if (!re.test(value)) {
                tempErrors[field.field_name] = 'Please enter a valid email';
                return false;
            }
            return true;
        },

        phone: (value, bool, field) => {
            // should only be numbers and of length 12 (+1..10digits)
            if (value.length !== 12) {
                tempErrors[field.field_name] =
                    'Please enter a valid phone number';
                return false;
            }
            return true;
        },
    };

    const createCateringInquiry = async () => {
        setLoading(true);
        let inquiryData = {
            ...form,
            source: source ? source : 'Website',
        };
        let reqBody = {
            restauId: restauId,
            inquiryData: inquiryData,
        };
        try {
            const response = await axios.post(
                Config.create_catering_inquiry,
                reqBody,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.data || response.data.status === false) {
                setSnackbarMessage(
                    'Issue submitting catering form. Please contact support if the issue persists.'
                );
                setSnackbarOpen(true);
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            }
            clearAll();
            navigate(`/catering/${restauId}/success`);
        } catch (error) {
            console.log('Caught error in createCateringInquiry: ', error);
            setSnackbarMessage(
                'Issue submitting catering form. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const updateCateringInquiry = async (
        restauId,
        inquiryId,
        eventId,
        updatedData
    ) => {
        setLoading(true);
        let reqBody = {
            restauId: restauId,
            inquiryId: inquiryId,
            eventId: eventId,
            updatedData: updatedData,
        };
        try {
            const response = await axios.post(
                Config.update_catering_inquiry,
                reqBody,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.data || response.data.status === false) {
                setSnackbarMessage(
                    'Issue submitting catering form. Please contact support if the issue persists.'
                );
                setSnackbarOpen(true);
                throw new Error(
                    'Error from the server: ' + response.data
                        ? response.data.msg
                        : null
                );
            }
            clearAll();
            navigate(`/catering/${restauId}/success`);
        } catch (error) {
            console.log('Caught error in updateCateringInquiry: ', error);
            setSnackbarMessage(
                'Issue submitting catering form. Please contact support if the issue persists.'
            );
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const getPhoneNumberInE164Format = (phNumber) => {
        if (!phNumber.trim()) return phNumber.trim();
        let number = phNumber
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll('-', '')
            .replaceAll('.', '')
            .replaceAll(' ', '');
        if (number.startsWith('+1')) {
            return number;
        } else if (number.startsWith('1')) {
            return '+' + number;
        } else {
            return '+1' + number;
        }
    };
    const submitForm = async (e) => {
        e.preventDefault();
        // clean up phone number input
        if (form.phone) {
            // we know updating the state directly is not good. but that is
            // how the code is working
            form.phone = getPhoneNumberInE164Format(form.phone);
            // still keeping this code.
            updateForm({ phone: getPhoneNumberInE164Format(form.phone) });
        }
        // conduct form validation
        tempErrors = {};
        for (let field of formConfig.fields) {
            for (let val in field.validation) {
                if (validationFunctions[val]) {
                    if (val === 'noEarlierThan') {
                        var valid = validationFunctions[val](
                            form[field.field_name],
                            form[field.validation[val]],
                            field
                        );
                    } else
                        var valid = validationFunctions[val](
                            form[field.field_name],
                            field.validation[val],
                            field
                        );

                    console.log(
                        field.field_name,
                        form[field.field_name],
                        val,
                        valid
                    );
                }
            }
        }
        if (Object.keys(tempErrors).length === 0) {
            setErrors(tempErrors);
            if (newFormToggle) await createCateringInquiry();
            else
                await updateCateringInquiry(
                    restauId,
                    inquiryId,
                    form.eventId,
                    form
                );
        } else {
            setErrors(tempErrors);
        }
    };

    const clearAll = () => {
        setForm(formFields);
    };

    const convertTimestamp = (timestamp, formatType) => {
        let date;
        if (timestamp && timestamp._seconds) {
            date = new Date(timestamp._seconds * 1000);
            switch (formatType) {
                case 1: // for MUI Datepicker
                    return moment.utc(date).format('YYYY-MM-DD');

                case 2: // Display date string "Mon, Nov 6, 2023"
                    return moment.utc(date).format('ddd, MMM D, YYYY');

                case 3: // Display date and time "Mon, Nov 6, 2023, 12:19PM"
                    return moment.utc(date).format('ddd, MMM D, YYYY, h:mmA');

                default:
                    return null;
            }
        } else {
            switch (formatType) {
                case 1: // not a timestamp foramt but a date string, return string to MUI Datepicker
                    return timestamp;

                case 2: // convert date string to "Mon, Nov 6, 2023"
                    date = new Date(timestamp);
                    if (isNaN(date.getTime())) return '-';
                    const formattedDate = date.toLocaleDateString('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                    });
                    return formattedDate;

                default:
                    return null;
            }
        }
    };

    return {
        retrieveFormConfig,
        formConfig,
        setFormConfig,
        form,
        setForm,
        strippedInquiryData,
        errors,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        loading,
        setLoading,
        updateForm,
        submitForm,
        clearAll,
        kitchenToggle,
        convertTimestamp,
    };
};

export default useForm;
