import React from 'react';
import Config from '../config/Config';
import { NavbarStyled, Logo } from '../Navbar/Navbar';
import { Tagline, MenuContent } from '../Menu/Menu2';
import Location from './Location';
import { ChainStyled, LocationGrid, LogoImg } from './Chain.styles';

export function Chain({ chainDetails }) {
    return chainDetails ? (
        <>
            <NavbarStyled>
                <Logo>
                    {Config.environment}
                    {chainDetails.name ? <>{chainDetails.name}</> : <></>}
                </Logo>
            </NavbarStyled>
            {chainDetails.logo && <LogoImg src={chainDetails.logo}></LogoImg>}
            <ChainStyled>
                <Tagline>{chainDetails.tagline}</Tagline>
                <MenuContent>
                    <LocationGrid>
                        {chainDetails.locations.map((location) => (
                            <Location key={location.id} {...location} />
                        ))}
                    </LocationGrid>
                </MenuContent>
            </ChainStyled>
        </>
    ) : (
        <></>
    );
}
