import getEndpoints from './getEndpoints';

let cloud_function_url =
    'https://us-central1-sliceline-takeout.cloudfunctions.net/';

const prodConfig = {
    environment: '',
    stripeConfig:
        'pk_live_51HrjUNHaof0IjCDrl0AZr0k9mHbmfXagLnUZQeUllX5ML4b2uPlnqQqkm5QIRqs5VmbLCHbn1xZrjiPEzXQNAWEp00Z60DqOva',
    firebaseConfig: {
        apiKey: 'AIzaSyD5quCrcrCloyNBSpeBy4_2aUmDNgdrfxM',
        authDomain: 'sliceline-takeout.firebaseapp.com',
        databaseURL: 'https://sliceline-takeout.firebaseio.com',
        projectId: 'sliceline-takeout',
        storageBucket: 'sliceline-takeout.appspot.com',
        messagingSenderId: '223301731732',
        appId: '1:223301731732:web:565079a27cfa18581d7b81',
        measurementId: 'G-TH05TEPR3R',
    },
    ...getEndpoints(cloud_function_url),
};

export default prodConfig;
