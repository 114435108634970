import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import React from 'react';
import styled from 'styled-components';

const RatingItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 4px;
    padding-bottom: 4px;
`;

function RatingItem({ id, label, defaultValue, readOnly, onChange }) {
    const handleChange = (e, value) => {
        onChange(id, value);
    };

    return (
        <RatingItemContainer>
            <Typography>{label}</Typography>
            <Rating
                name={id}
                defaultValue={defaultValue}
                precision={0.5}
                readOnly={readOnly}
                onChange={handleChange}
            />
        </RatingItemContainer>
    );
}

RatingItem.propTypes = {};

export default RatingItem;
