import styled from 'styled-components';
import { corianderGreen } from '../Styles/colors';

const DialogContent = styled.div`
    overflow: scroll;

    min-height: 20px;
    padding: 0px 15px;
    padding-bottom: 10px;
    @media (max-width: 720px) {
        overflow: visible;
        min-height: 20px;
        padding: 20px 15px;
        // margin-bottom: 40px;
    }
`;
DialogContent.displayName = 'DialogContent';

const DialogFooter = styled.div`
    box-shadow: 0px -2px 0px 0px #e7e7e7;
    display: flex;
    justify-content: center;
    min-height: 60px;
`;
DialogFooter.displayName = 'DialogFooter';

const OrderStyled = styled.div`
    position: fixed;
    right: 0px;
    top: 39px;
    width: 400px;
    background-color: white;
    height: calc(100% - 39px);
    z-index: 10;
    box-shadow: 4px 0px 5px 4px grey;
    display: flex;
    flex-direction: column;

    @media (max-width: 720px) {
        position: relative;
        width: 100%;
    }
`;
OrderStyled.displayName = 'OrderStyled';

const ButtonContainer = styled.div`
    height: 60px;
    display: flex;
    justify-content: center;
`;
ButtonContainer.displayName = 'ButtonContainer';

const CenterDiv = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`;
CenterDiv.displayName = 'CenterDiv';

const OrderContent = styled(DialogContent)`
    padding: 20px;
    height: 100%;
`;
OrderContent.displayName = 'OrderContent';

const OrderContainer = styled.div`
    padding: 5px 0px;
    border-bottom: 1px solid rgba(39, 174, 96, 0.5);
    ${({ editable }) =>
        editable
            ? `
  &:hover {
    cursor: pointer;
    background-color: #f8f8f8;
  }
`
            : `
  pointer-events: none; 
`}
`;
OrderContainer.displayName = 'OrderContainer';

const OrderContainerClickable = styled.div`
    padding: 5px 0px;
    border-bottom: 1px solid rgba(39, 174, 96, 0.5);
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;

    color: #222c31;
`;
OrderContainerClickable.displayName = 'OrderContainerClickable';

const OrderContainerDiv = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.01em;

    color: #222c31;
`;
OrderContainerDiv.displayName = 'OrderContainerDiv';

const CheckoutPageInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
`;
CheckoutPageInputContainer.displayName = 'CheckoutPageInputContainer';

const OrderContainerClickableWithoutBorder = styled.div`
    padding: 5px 0px;
`;
OrderContainerClickableWithoutBorder.displayName =
    'OrderContainerClickableWithoutBorder';

//  grid-template-columns: 20px 150px 20px 60px;
const OrderItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: minmax(50px, 150px) 25px 25px 25px 50px;
    justify-content: space-between;
    column-gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
`;
OrderItem.displayName = 'OrderItem';

const BillItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: auto 60px;
    justify-content: space-between;
`;
BillItem.displayName = 'BillItem';

const RatingItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: minmax(50px, 150px) 100px 20px 50px;
    justify-content: space-between;
`;
RatingItem.displayName = 'RatingItem';

const AddMoreItemDiv = styled.span`
    padding-left: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height, or 114% */

    letter-spacing: -0.01em;

    color: #222c31;
`;
AddMoreItemDiv.displayName = 'AddMoreItemDiv';

const OverallRatingItem = styled.div`
    padding: 5px 0px;
    display: grid;
    grid-template-columns: minmax(50px, 150px) 170px;
    justify-content: space-between;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: -0.01em;

    color: #000000;
`;
OverallRatingItem.displayName = 'OverallRatingItem';

const CartDialogShadow = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    background-color: black;
    opacity: 0.7;
    z-index: 9;
`;
CartDialogShadow.displayName = 'CartDialogShadow';

const DetailItem = styled.div`
    color: gray;
    font-size: 10px;
`;
DetailItem.displayName = 'DetailItem';

const RightAlignPrice = styled.div`
    text-align: right;
`;
RightAlignPrice.displayName = 'RightAlignPrice';

const StarRating = styled.div`
    text-align: left;
`;
StarRating.displayName = 'StarRating';

const StarRatingRightAlign = styled.div`
    text-align: right;
`;
StarRatingRightAlign.displayName = 'StarRatingRightAlign';

const OutsideBizHoursMessage = styled.div`
    color: #e74c3c;
    font-size: 15px;
    word-spacing: 0px;
    text-align: center;
    margin-top: 20px;
    fontweight: bold;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    /* or 150% */
    letter-spacing: -0.01em;
`;
OutsideBizHoursMessage.displayName = 'OutsideBizHoursMessage';

const TipOptions = styled.div`
    display: flex;
    justify-content: space-between;
`;
TipOptions.displayName = 'TipOptions';

const TipLabel = styled.div`
    margin-right: 10px;
    display: flex;
    align-items: center;
`;
TipLabel.displayName = 'TipLabel';

const RadioInput = styled.input`
    cursor: pointer;
    margin-right: 3px;
    vertical-align: middle;
`;
RadioInput.displayName = 'RadioInput';

const OrderAheadLabel = styled.label`
    display: inline-block;
    padding-right: 10px;
    white-space: nowrap;
`;
OrderAheadLabel.displayName = 'OrderAheadLabel';

const OrderAheadCheckbox = styled.input`
    vertical-align: middle;
`;
OrderAheadCheckbox.displayName = 'OrderAheadCheckbox';

const VerticalAlignSpan = styled.span`
    vertical-align: middle;
`;
VerticalAlignSpan.displayName = 'VerticalAlignSpan';

const OrderAheadSelect = styled.select`
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 0.85rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    border: 2px solid #36b37e;
    border-radius: 5px;
    margin-right: 5px;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
`;
OrderAheadSelect.displayName = 'OrderAheadSelect';

// TODO: might be useful when we show orderstatus.
// const orderStatusDict = {
// "WAITER" : "🤵",
// "COOK" : "👨‍🍳",
// "DELIVERED" : "🍽"
// };

// const orderStatusLabel = {
//   "WAITER" : "Host",
//   "COOK" : "Cook",
//   "DELIVERED" : "Table"
// };

const PromotionCard = styled.div`
box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.2);
width: 98%;
margin-right: 30px;
margin-bottom: 20px;
padding-left: 10px;
padding-top: 5px;
padding-bottom: 10px;
color: #cc4d29;
font-size: 18px;

}`;
PromotionCard.displayName = 'PromotionCard';

const RatingsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.01em;

    color: #222c31;
`;
RatingsContainer.displayName = 'RatingsContainer';

const QsrTableDiv = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    color: #222c31;
    text-align: center;
`;
QsrTableDiv.displayName = 'QsrTableDiv';

const HeySpan = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    color: #222c31;
`;
HeySpan.displayName = 'HeySpan';

const OrderItemSpan = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #222c31;
    padding-bottom: 20px;
`;
OrderItemSpan.displayName = 'OrderItemSpan';

const GreyLabelDiv = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    /* or 150% */

    letter-spacing: -0.01em;

    color: #7c7d7d;
`;
GreyLabelDiv.displayName = 'GreyLabelDiv';

const HighlightedLabelDiv = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    /* identical to box height, or 100% */

    color: #222c31;
`;
HighlightedLabelDiv.displayName = 'HighlightedLabelDiv';

const OrderPickupDiv = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    color: #222c31;
    display: inline-block;
`;
OrderPickupDiv.displayName = 'OrderPickupDiv';

const IncrementButton = styled.div`
    color: ${corianderGreen};
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    text-align: left;

    ${({ disabled }) =>
        disabled &&
        `opacity: 0.5; 
   pointer-events: none; 
   `}
    &:hover {
        background-color: '#81ecec';
    }
`;
IncrementButton.displayName = 'IncrementButton';

const TotalItemDiv = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    /* identical to box height, or 100% */

    color: #222c31;
`;
TotalItemDiv.displayName = 'TotalItemDiv';

const ItemTitle = styled.div`
    padding-left: 0px;

    letter-spacing: -0.01em;

    color: #222c31;
`;
ItemTitle.displayName = 'ItemTitle';

const SmallImageSpan = styled.img`
    width: 61.12px;
    height: 34.24px;
`;
SmallImageSpan.displayName = 'SmallImageSpan';

export {
    DialogContent,
    DialogFooter,
    OrderStyled,
    ButtonContainer,
    CenterDiv,
    OrderContent,
    OrderContainer,
    OrderContainerClickable,
    OrderContainerDiv,
    CheckoutPageInputContainer,
    OrderContainerClickableWithoutBorder,
    OrderItem,
    BillItem,
    RatingItem,
    AddMoreItemDiv,
    OverallRatingItem,
    CartDialogShadow,
    DetailItem,
    RightAlignPrice,
    StarRating,
    StarRatingRightAlign,
    OutsideBizHoursMessage,
    TipOptions,
    TipLabel,
    RadioInput,
    OrderAheadLabel,
    OrderAheadCheckbox,
    VerticalAlignSpan,
    OrderAheadSelect,
    PromotionCard,
    RatingsContainer,
    QsrTableDiv,
    HeySpan,
    OrderItemSpan,
    GreyLabelDiv,
    HighlightedLabelDiv,
    OrderPickupDiv,
    IncrementButton,
    TotalItemDiv,
    ItemTitle,
    SmallImageSpan,
};
