import React from 'react';
import Collapsible from '../Collapsible';
import PropTypes from 'prop-types';

import FoodItem from './FoodItem';
import FoodItem2 from './FoodItem2';
import FoodItemImageLeft from './FoodItemImageLeft';
import FoodItemImageRight from './FoodItemImageRight';

import { FoodGrid, FoodGrid2, FoodGridRight } from './FoodItem.styles';

const foodGrids = {
    tuba: FoodGrid2,
    imageleft: FoodGrid,
    imageright: FoodGridRight,
    default: FoodGridRight,
};

const foodItems = {
    tuba: FoodItem2,
    imageleft: FoodItemImageLeft,
    imageright: FoodItemImageRight,
    default: FoodItem,
};

function FoodSection({
    id,
    name,
    foods,
    showCount,
    setOpenFood,
    sidx,
    template,
}) {
    const Grid = foodGrids[template] || foodGrids.default;
    const Item = foodItems[template] || foodItems.default;

    return (
        <Collapsible
            key={id}
            trigger={name + (showCount ? ` (${foods.length})` : '')}
            open={sidx === 0}
        >
            <Grid>
                {foods &&
                    foods.map((food, fidx) =>
                        food ? (
                            <Item
                                key={id + food.id}
                                food={food}
                                setOpenFood={setOpenFood}
                            />
                        ) : (
                            <div key={sidx + ' ' + fidx} />
                        )
                    )}
            </Grid>
        </Collapsible>
    );
}

FoodSection.propTypes = {};

export default FoodSection;
