import { useEffect, useState } from 'react';

export function useAuthentication() {
    const [authenticated, setAuthenticated] = useState('loading');

    function login() {
        setAuthenticated('logged_in');
    }

    function logout() {}

    useEffect(() => {}, []);

    return { login, logout, loggedIn: authenticated };
}
