import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Link from '@material-ui/core/Link';
import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.img`
    width: 200px;
    height: 44px;
    margin: 0 auto;
`;

const PromotionCard = styled(Card)`
    margin: 10px 0;
`;
function Promotion({ message, link, logo }) {
    return (
        <PromotionCard>
            <CardHeader
                title={message}
                titleTypographyProps={{ color: 'error', variant: 'h6' }}
            />
            {link ? (
                <Link href={link} color="inherit" target="_blank">
                    <StyledLogo
                        src={logo}
                        alt="Promotion logo"
                        width="250"
                        height="54"
                    />
                </Link>
            ) : null}
        </PromotionCard>
    );
}

Promotion.propTypes = {};

export default Promotion;
