import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import styled from 'styled-components';
import Caption from './Caption';
import handleKeyPress from '../utils/handleKeyPress';

const CustomTipWrapper = styled.div`
    display: flex;
    border: ${({ selected }) =>
        selected ? '2px solid green' : '1px solid #eee'};
    background-color: ${({ selected }) =>
        selected
            ? 'lightgreen'
            : 'transparent'}; // TODO: link to theme colors/styles
    border-radius: 6px;
`;
function TipCustom({
    isCustom,
    customTipPercent,
    customTipValue = 0,
    onChange,
}) {
    return (
        <div>
            <CustomTipWrapper selected={isCustom}>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    value={customTipPercent}
                    style={{ width: 60, fontSize: '1em' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                style={{ marginLeft: 0 }}
                                position="end"
                            >
                                %
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{
                        type: 'number',
                        max: 100,
                        min: 0,
                        style: {
                            textAlign: 'right',
                            paddingLeft: 4,
                        },
                    }}
                    placeholder="any"
                    onChange={onChange}
                    onClick={onChange}
                    onKeyPress={handleKeyPress}
                />
            </CustomTipWrapper>
            {isCustom && (
                <div style={{ textAlign: 'center' }}>
                    <Caption>{customTipValue}</Caption>
                </div>
            )}
        </div>
    );
}

TipCustom.propTypes = {};

export default TipCustom;
