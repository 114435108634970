import { getPrice } from '../FoodDialog/FoodDialog';

export function getSubTotal(tableOrders = {}) {
    const subtotal = Object.keys(tableOrders).reduce((total, key) => {
        return total + getPrice(tableOrders[key]);
    }, 0);
    return subtotal;
}

export function getTax(amount, taxPercent) {
    return Math.round(amount * (taxPercent / 100.0));
}

export function getTip(amount, tipPercent) {
    if (tipPercent && !isNaN(parseFloat(tipPercent))) {
        var ret = (amount / 100.0) * parseFloat(tipPercent);
        ret = Math.round(ret);
        return ret;
    }
    return 0;
}

export function getCreditCardFees(amount, minAmount, minFees) {
    if (amount < minAmount) {
        return minFees;
    }
    return 0;
}

export function getDeliveryFeesActual(
    amount,
    orderType,
    deliveryThreshold,
    deliveryFees
) {
    if (
        orderType === 'Delivery' &&
        (!deliveryThreshold ||
            deliveryThreshold === 0 ||
            amount < deliveryThreshold)
    ) {
        return deliveryFees;
    }
    return 0;
}

export function getOrderReadyByString(scheduledDate, scheduledTime) {
    if (scheduledDate && scheduledTime) {
        return ' on ' + scheduledDate + ' by ' + scheduledTime;
    }
    return ' as soon as possible';
}
