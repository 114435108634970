import React, { useContext } from 'react';
import { OrderContext } from '../../context/orderContext';
import Instructions from '../components/Instructions';

function OrderInstructions(props) {
    const { orderInstruction, setOrderInstruction } = useContext(OrderContext);

    const handleChange = (event) => {
        setOrderInstruction(event.target.value);
    };

    return (
        <Instructions
            onChange={handleChange}
            value={orderInstruction}
            {...props}
        />
    );
}

OrderInstructions.propTypes = {};

export default OrderInstructions;
