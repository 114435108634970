import { useEffect, useState } from 'react';

export function useShowOrders() {
    const [showOrders, setShowOrders] = useState(false);
    function toggleShowOrders() {
        console.log('showOrders', showOrders);
        setShowOrders(!showOrders);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showOrders]);

    return {
        showOrders,
        setShowOrders,
        toggleShowOrders,
    };
}
