import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import React from 'react';
import styled from 'styled-components';

const InputLabelStyled = styled(InputLabel)`
    background-color: #fff;
    padding: 0 5px !important;
`;

function Dropdown({ id, label, name, options, value, onChange }) {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <FormControl variant="outlined" size="small" fullWidth>
            <InputLabelStyled shrink>{label}</InputLabelStyled>
            <Select
                native
                labelId={`${label}-id`}
                value={value}
                onChange={handleChange}
                inputProps={{
                    name: name,
                    id: id,
                }}
            >
                {options.map((item) => (
                    <option
                        aria-label={item.ariaLabel}
                        value={item.value}
                        key={item.value}
                    >
                        {item.label}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
}

Dropdown.propTypes = {};

export default Dropdown;
