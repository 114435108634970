import React from 'react';
import OrderInstructions from '../containers/OrderInstructions';
import OrderTelephone from '../containers/OrderTelephone';
import OrderUnderName from '../containers/OrderUnderName';

function OrderSpecifics() {
    return (
        <div>
            <OrderUnderName />
            <OrderTelephone />
            <OrderInstructions />
        </div>
    );
}

OrderSpecifics.propTypes = {};

export default OrderSpecifics;
