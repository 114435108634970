import { useEffect } from 'react';

export function useTitle({ openFood, orders, restauName }) {
    useEffect(() => {
        if (openFood) {
            document.title = openFood.name;
        } else {
            if (orders) {
                document.title =
                    restauName !== ''
                        ? restauName + ' Online Ordering'
                        : Object.keys(orders).length === 0
                        ? `Start Ordering Quickly!`
                        : `[${Object.keys(orders).length}] items ordered! `;
            }
        }
    });
}
