import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const CartActionContainer = styled.div`
    margin-top: 10px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 10;
    width: 100%;
`;
function CartAction({ label = 'Add Item', disabled, pending, onCartSubmit }) {
    const progress = <CircularProgress size={20} style={{ color: 'black' }} />;
    return (
        <CartActionContainer>
            <Button
                disabled={disabled}
                onClick={onCartSubmit}
                color="primary"
                variant="contained"
                fullWidth
                size="large"
            >
                {pending ? progress : label}
            </Button>
        </CartActionContainer>
    );
}

CartAction.propTypes = {};

export default CartAction;
