import React from 'react';
import styled from 'styled-components';
import { formatPrice } from '../Data/FoodData';
import { LabelContainer } from './FoodDialog';
const ChoiceGrid = styled.div`
    display: grid;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #222c31;
    grid-template-columns: repeat(1, 1fr);
    @media (max-width: 720px) {
        grid-template-columns: 1fr;
    }
`;

const ChoiceDiv = styled.div`
    margin-top: 20px;
`;

const CursorPointer = `cursor: pointer;`;

export const RadioInput = styled.input`
    ${CursorPointer}
    margin-right: 10px;
    border: 2px solid #36b37e;
    box-sizing: border-box;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    margin-top: 5px;
    vertical-align: middle;
    color: #222c31;
`;

//TODO: replace label by Label and confirm the UX.
// const Label = styled.label`
//   ${CursorPointer}
// `;

export function Choices({ openFood, choiceRadio }) {
    function radioOnChange(e) {
        choiceRadio.onChange(e);
    }

    var samePrice = true;
    for (var i = 1; i < openFood.choices.length; i++) {
        if (openFood.choices[i - 1].price !== openFood.choices[i].price) {
            samePrice = false;
        }
    }

    return (
        <ChoiceDiv>
            {openFood.choiceslabel ? (
                <LabelContainer>
                    <b>{openFood.choiceslabel}</b>
                </LabelContainer>
            ) : (
                <LabelContainer>
                    <b>Choose one:</b>
                </LabelContainer>
            )}
            <ChoiceGrid>
                {openFood.choices.map((choice) => (
                    <>
                        <label>
                            <RadioInput
                                type="radio"
                                id={choice.name}
                                name="choice"
                                value={choice.name}
                                checked={choiceRadio.value === choice.name}
                                onChange={radioOnChange}
                            />
                            {choice.name}
                            {choice.price && !samePrice && (
                                <span>
                                    {' '}
                                    ({formatPrice(choice.price / 100)})
                                </span>
                            )}
                        </label>
                        {/* <Label for={choice}> {choice.name} </Label>{" "} */}
                    </>
                ))}
            </ChoiceGrid>
        </ChoiceDiv>
    );
}
