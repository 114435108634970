import React from 'react';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
const moment = require('moment');

const LeadDetails = ({ details }) => {
    const dateTimeStr = () => {
        let str = '';
        if (details.eventDate) {
            str += moment(details.eventDate).format('dddd MMM D, YYYY');
        }
        if (details.startTime) {
            str += ` at ${details.startTime}`;
        }
        return str;
    };

    return (
        <Table size="small" sx={{ mb: 2 }}>
            <TableBody>
                {details.customerName && (
                    <TableRow>
                        <TableCell
                            variant="head"
                            align="left"
                            sx={{ fontWeight: 'bold' }}
                        >
                            Name:
                        </TableCell>
                        <TableCell align="left">
                            {details.customerName}
                        </TableCell>
                    </TableRow>
                )}

                {details.eventDate && (
                    <TableRow>
                        <TableCell
                            variant="head"
                            align="left"
                            sx={{ fontWeight: 'bold' }}
                        >
                            Date/Time:
                        </TableCell>
                        <TableCell align="left">{dateTimeStr()}</TableCell>
                    </TableRow>
                )}

                {details.phone && (
                    <TableRow>
                        <TableCell
                            variant="head"
                            align="left"
                            sx={{ fontWeight: 'bold' }}
                        >
                            Phone:
                        </TableCell>
                        <TableCell align="left">{details.phone}</TableCell>
                    </TableRow>
                )}

                {details.email && (
                    <TableRow>
                        <TableCell
                            variant="head"
                            align="left"
                            sx={{ fontWeight: 'bold' }}
                        >
                            Email:
                        </TableCell>
                        <TableCell align="left">{details.email}</TableCell>
                    </TableRow>
                )}

                {details.numOfGuests && (
                    <TableRow>
                        <TableCell
                            variant="head"
                            align="left"
                            sx={{ fontWeight: 'bold' }}
                        >
                            # of Guests:
                        </TableCell>
                        <TableCell align="left">
                            {details.numOfGuests}
                        </TableCell>
                    </TableRow>
                )}

                {details.specialIns && (
                    <TableRow>
                        <TableCell
                            variant="head"
                            align="left"
                            sx={{ fontWeight: 'bold' }}
                        >
                            Special Instructions:
                        </TableCell>
                        <TableCell align="left">{details.specialIns}</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export default LeadDetails;
