import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import Config from '../../config/Config';
import { OrderContext } from '../../context/orderContext';
import FormContext from '../FormContext';
import OrderItem from './OrderItem';
import OrderTotalEntry from '../../Order/components/OrderTotalEntry';
import LeadDetails from './LeadDetails';
import {
    Grid,
    Typography,
    Divider,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import styled from 'styled-components';
import { corianderGreen } from '../../Styles/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { formatPrice } from '../../Data/FoodData';
const { default: axios } = require('axios');

const PaymentButton = styled('div')`
    margin: auto;
    color: white;
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px;
    padding: 7px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    background-color: ${corianderGreen};
    font-family: 'Poppins';
`;

const ReceiptButton = styled('div')`
    margin: auto;
    color: ${corianderGreen};
    font-weight: bold;
    font-size: 15px;
    border: solid;
    border-color: ${corianderGreen};
    border-radius: 5px;
    padding: 7px;
    text-align: center;
    width: 200px;
    cursor: pointer;
    background-color: white;
    font-family: 'Poppins';
`;

const OrderSummary = () => {
    const { strippedInquiryData, kitchenToggle } = useContext(FormContext);
    const { enqueueSnackbar } = useContext(OrderContext);
    const [depositPending, setDepositPending] = useState(false);
    const [invoicePending, setInvoicePending] = useState(false);
    const { restauId, inquiryId } = useParams();

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        console.log('params: ', params);
        let status = params.get('stripeCateringRedirect')
            ? params.get('stripeCateringRedirect')
            : 'None';

        if (status === 'success') {
            enqueueSnackbar(
                'Payment successful. Your order will be ready soon.',
                { variant: 'success' }
            );
        } else if (status === 'cancel') {
            enqueueSnackbar('Payment cancelled.', { variant: 'error' });
        }
    }, []);

    const makePayment = async (paymentType) => {
        if (paymentType === 'deposit') {
            setDepositPending(true);
        } else if (paymentType === 'deposit') {
            setInvoicePending(true);
        }
        const database = window.customerApp.database();
        var paymentRef = database.ref('menus/' + restauId + '/payments');
        let snapshot = await paymentRef.once('value');
        var paymentRefResult = snapshot.val();
        var strAcct = '';
        let isConnectedStripeAccount = false;
        let connectedStripeAccount = null;
        if (paymentRefResult) {
            if (!Config.environment) {
                // environment = ["Dev-", "Stage-", ""] last one is prod
                strAcct = paymentRefResult.stripeAccount;
            } else {
                strAcct = paymentRefResult.stripeAccountTest;
            }

            if (paymentRefResult.isConnectedStripeAccount) {
                isConnectedStripeAccount =
                    paymentRefResult.isConnectedStripeAccount;
            }
            if (paymentRefResult.connectedStripeAccount) {
                connectedStripeAccount =
                    paymentRefResult.connectedStripeAccount;
            }
        }

        var stripePromise = null;
        if (isConnectedStripeAccount && connectedStripeAccount) {
            stripePromise = loadStripe(Config.stripeConfig);
        } else if (strAcct) {
            stripePromise = loadStripe(Config.stripeConfig, {
                stripeAccount: strAcct,
            });
        } else {
            stripePromise = loadStripe(Config.stripeConfig);
        }

        const stripe = await stripePromise;
        var url = Config.make_catering_payment;
        var data;
        try {
            var resp = await axios.post(url, {
                restauId: restauId,
                inquiryId: inquiryId,
                eventId: strippedInquiryData.leadDetails.eventId,
                paymentType: paymentType,
            });
            data = resp.data;
        } catch (error) {
            console.log('Error calling makePayment');
            if (paymentType === 'deposit') {
                setDepositPending(false);
            } else if (paymentType === 'invoice') {
                setInvoicePending(false);
            }
            enqueueSnackbar(
                'Transaction is not successful, Please try again.',
                { variant: 'error' }
            );
            return;
        }
        console.log('makePayment response', data);

        if (paymentType === 'deposit') {
            setDepositPending(false);
        } else if (paymentType === 'invoice') {
            setInvoicePending(false);
        }
        const result = await stripe.redirectToCheckout({
            sessionId: data.sessionId,
        });

        if (result.error) {
            console.log(result);
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.

            var errmsg = 'Error. Try payment again.';
            if ('message' in result.error) {
                errmsg =
                    'Error: ' + result.error.message + '. Try payment again.';
            }
            enqueueSnackbar(errmsg, { variant: 'error' });
        }
        if (paymentType === 'deposit') {
            setDepositPending(false);
        } else if (paymentType === 'invoice') {
            setInvoicePending(false);
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <LeadDetails details={strippedInquiryData.leadDetails} />
            </Grid>

            <Grid item xs={12}>
                <Typography sx={{ font: '0.7rem', fontWeight: 'bold' }}>
                    Summary of your ordered items:
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {Object.values(strippedInquiryData.orders.items).map((item) => (
                    <OrderItem
                        key={item.key}
                        item={item}
                        kitchenToggle={kitchenToggle}
                    />
                ))}
            </Grid>

            {!kitchenToggle && (
                <Grid
                    item
                    xs={12}
                    style={{ fontFamily: 'TT Norms Pro,Roboto,sans-serif' }}
                >
                    <OrderTotalEntry
                        label="SubTotal"
                        value={
                            strippedInquiryData.orders
                                .payment_subtotal_before_discount
                        }
                        variant="body2"
                    />
                    {strippedInquiryData.orders.discount.amount !== 0 && (
                        <OrderTotalEntry
                            label="Discount"
                            value={strippedInquiryData.orders.discount.amount}
                            variant="body2"
                        />
                    )}
                    <OrderTotalEntry
                        label="Tax"
                        value={strippedInquiryData.orders.payment_salestax}
                        variant="body2"
                    />
                    <Divider />
                    <OrderTotalEntry
                        label="Total"
                        value={strippedInquiryData.orders.payment_total}
                    />
                    {strippedInquiryData.orders.deposit_payment_success &&
                        strippedInquiryData.orders.payment_total !==
                            strippedInquiryData.orders.total_amount_paid && (
                            <>
                                <OrderTotalEntry
                                    label="Deposit paid"
                                    value={
                                        strippedInquiryData.orders
                                            .deposit_amount
                                    }
                                />
                                <OrderTotalEntry
                                    label="Pending total"
                                    value={
                                        strippedInquiryData.orders
                                            .payment_total -
                                        strippedInquiryData.orders
                                            .total_amount_paid
                                    }
                                />
                            </>
                        )}
                </Grid>
            )}

            <Grid
                container
                spacing={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                {!kitchenToggle &&
                    strippedInquiryData.orders.deposit_payment &&
                    (strippedInquiryData.orders.deposit_payment_success ? (
                        <Grid item xs={12} lg={6}>
                            <ReceiptButton
                                onClick={() => {
                                    window.open(
                                        strippedInquiryData.orders
                                            .deposit_stripe_strideq_receipt_url,
                                        '_blank'
                                    );
                                }}
                            >
                                View Deposit Receipt
                            </ReceiptButton>
                        </Grid>
                    ) : (
                        strippedInquiryData.orders.payment_total !==
                            strippedInquiryData.orders.total_amount_paid && (
                            <Grid item xs={12} lg={6}>
                                <PaymentButton
                                    onClick={() => {
                                        makePayment('deposit');
                                    }}
                                >
                                    {depositPending ? (
                                        <CircularProgress
                                            size={20}
                                            style={{ color: 'black' }}
                                        />
                                    ) : (
                                        <>{`Pay Deposit: ${formatPrice(
                                            strippedInquiryData.orders
                                                .deposit_amount / 100
                                        )}`}</>
                                    )}
                                </PaymentButton>
                            </Grid>
                        )
                    ))}
                {!kitchenToggle &&
                    strippedInquiryData.orders.invoice_payment &&
                    (strippedInquiryData.orders.invoice_payment_success ? (
                        <Grid item xs={12} lg={6}>
                            <ReceiptButton
                                onClick={() => {
                                    window.open(
                                        strippedInquiryData.orders
                                            .invoice_stripe_strideq_receipt_url,
                                        '_blank'
                                    );
                                }}
                            >
                                View Invoice Receipt
                            </ReceiptButton>
                        </Grid>
                    ) : (
                        <Grid item xs={12} lg={6}>
                            <PaymentButton
                                onClick={() => {
                                    makePayment('invoice');
                                }}
                            >
                                {invoicePending ? (
                                    <CircularProgress
                                        size={20}
                                        style={{ color: 'black' }}
                                    />
                                ) : (
                                    <>{`Pay Invoice: ${formatPrice(
                                        (strippedInquiryData.orders
                                            .payment_total -
                                            strippedInquiryData.orders
                                                .deposit_amount_paid) /
                                            100
                                    )}`}</>
                                )}
                            </PaymentButton>
                        </Grid>
                    ))}
            </Grid>

            {strippedInquiryData.orders.orderInstruction && (
                <Grid item xs={12}>
                    <Table size="small" sx={{ mb: 2 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    variant="head"
                                    align="left"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    Order Instructions:
                                </TableCell>
                                <TableCell align="left">
                                    {
                                        strippedInquiryData.orders
                                            .orderInstruction
                                    }
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            )}

            {kitchenToggle &&
                strippedInquiryData.orders.orderInstructionInternal && (
                    <Grid item xs={12}>
                        <Table size="small" sx={{ mb: 2 }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        variant="head"
                                        align="left"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        Internal Order Instructions:
                                    </TableCell>
                                    <TableCell align="left">
                                        {
                                            strippedInquiryData.orders
                                                .orderInstructionInternal
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                )}
        </Grid>
    );
};

export default OrderSummary;
