/**
 * Options are:
 *  - Pickup
 *  - DineIn
 *  - Delivery
 *  - ToGo
 */
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { OrderContext } from '../../context/orderContext';

import { DINE_IN, TO_GO, DELIVERY } from '../constants';

const orderTypes = {
    [DINE_IN]: {
        value: DINE_IN,
        label: 'Dine In',
        show: false,
    },
    [TO_GO]: {
        value: TO_GO,
        label: 'To Go',
        show: true,
    },
    [DELIVERY]: {
        value: DELIVERY,
        label: 'Delivery',
        show: false,
    },
};

const ButtonGroupContainer = styled.div`
    margin-top: 5px;
`;
function OrderType() {
    const { orderType, setOrderType, customerType, enableDelivery } =
        useContext(OrderContext);

    const showDineIn =
        customerType === 'QSR-Dine-In' || customerType === 'Walk-In';

    const showToGo =
        customerType === 'QSR-Dine-In' || customerType === 'Walk-In';

    const showDelivery = enableDelivery;

    orderTypes[DINE_IN].show = showDineIn;

    orderTypes[DELIVERY].show = showDelivery;

    orderTypes[TO_GO].label = showToGo ? 'To Go' : 'Pickup';
    orderTypes[TO_GO].show = true;

    const list = Object.values(orderTypes);

    return (
        <div>
            <FormControl margin="normal" fullWidth>
                <FormLabel>Select Order Type</FormLabel>
                <ButtonGroupContainer>
                    <ButtonGroup fullWidth aria-label="order types">
                        {list.map(({ label, value, show }) =>
                            show ? (
                                <Button
                                    key={value}
                                    disabled={!show}
                                    aria-label={label}
                                    color="primary"
                                    onClick={() => setOrderType(value)}
                                    style={{ width: 100 }}
                                    variant={
                                        value === orderType
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                >
                                    {label}
                                </Button>
                            ) : null
                        )}
                    </ButtonGroup>
                </ButtonGroupContainer>
            </FormControl>
        </div>
    );
}

OrderType.propTypes = {};

export default OrderType;
