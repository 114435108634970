import styled from 'styled-components';
import { Title } from '../Styles/title';

export const FoodGrid = styled.div`
    @media (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        gap: 10px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
`;

// ${props => props.img !== undefined && `
//   position: absolute;
//   justify-content: space-between;
// `}

export const FoodLabel = styled(Title)`
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.75);
`;

//  height: ${props => props.img === undefined ? '40px' : '100px'}
export const Food = styled.div`
    height : 100px;
    padding: 7px 0px; 
    font-size: 20px; 
    background-image: ${({ img }) => `url(${img});`} 
    background-color: ${({ img }) => !img && '#ddd;'} 
    background-position: center;
    background-size: cover;
    filter: contrast(90%); 
    border-radius: 7px; 
    margin-top: 5px; 
    transition-property: box-shadow margin-top filter; 
    transition-duration: .1s; 
    box-shadow: 0px 0px 1px 1px #f2f2f2;
    &:hover {
        cursor: pointer; 
        filter: contrast(100%); 
        margin-top: 0px; 
        margin-bottom: 5px; 
        box-shadow: 0px 5px 10px 0px #f2f2f2;
    }
`;
