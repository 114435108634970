export const setLocalStorageWithExpiry = function (key, value) {
    const ttl = 1 * 60 * 60 * 1000; //1 is hour
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpiry = function (key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (!item.expiry || !item.value || now.getTime() > item.expiry) {
        localStorage.setItem(key, JSON.stringify({}));
        return null;
    }
    return item.value;
};
