/**
 * This is for online orders only
 * User can edit cart, delete items from cart, add items to cart
 * Finally goes to checkout screen.
 */

import React, { useContext, useState } from 'react';
import { DELIVERY, TO_GO } from './constants';
import { OrderContext } from '../context/orderContext';
import AddMore from './components/AddMore';
import CartAction from './components/CartAction';
import CartItems from './components/CartItems';
import DeliveryInfo from './components/DeliveryInfo';
import OrderAhead from './components/OrderAhead';
import OrderSpecifics from './components/OrderSpecifics';
import OrderTitle from './components/OrderTitle';
import OrderTotal from './components/OrderTotalSection';
import OrderType from './components/OrderType';
import OutsideHours from './components/OutsideHours';
import Promotion from './components/Promotion';
import TimeToCard from './components/TimeToCard';
import TipSection from './components/TipSection';
import ThirdParty from './components/ThirdParty';
import placeOrder from './utils/placeOrder';
function CartFilled({ toggleShowOrders }) {
    const [pending, setPending] = useState(false);
    const context = useContext(OrderContext);

    const {
        orders,
        qsrMode,
        tableId,
        promotionMessages,
        enablePayLater,
        areOrdersPaused,
        outsideBizHours,
        orderType,
        isOrderAheadEnabled,
        deliveryConfig,
        prepTimeConfig,
        thirdPartyConfig,
        isDeliveryAheadEnabled,
        deliveryTimings,
        isDeliveryDisabled,
    } = context;

    const [acceptOrderForLater, setAcceptOrderForLater] =
        useState(enablePayLater);

    return (
        // shows up before payment
        <div>
            {promotionMessages && promotionMessages.checkout_message && (
                <Promotion
                    message={promotionMessages.checkout_message}
                    link={promotionMessages.checkout_logo_link}
                    logo={promotionMessages.checkout_logo}
                />
            )}

            <OrderTitle qsrMode={qsrMode} tableId={tableId}>
                your order items so far:
            </OrderTitle>

            <CartItems />

            <AddMore onAddClick={toggleShowOrders} />

            <TipSection />

            <OrderTotal compute={true} />
            <OrderSpecifics />
            <ThirdParty />
            <OrderType />

            {orderType === DELIVERY && <DeliveryInfo />}

            {isOrderAheadEnabled && orderType === TO_GO && <OrderAhead />}
            {outsideBizHours && (
                <OutsideHours
                    selected={acceptOrderForLater}
                    onChange={setAcceptOrderForLater}
                />
            )}

            {orderType === DELIVERY &&
            !outsideBizHours &&
            deliveryConfig &&
            deliveryConfig.enabled &&
            deliveryConfig.estimatedTimeInMins ? (
                <TimeToCard
                    message="Estimated Time for the delivery order is"
                    duration={`${deliveryConfig.estimatedTimeInMins} minutes`}
                />
            ) : null}

            {orderType !== DELIVERY &&
            !outsideBizHours &&
            prepTimeConfig &&
            prepTimeConfig.enabled &&
            prepTimeConfig.estimatedTimeInMins ? (
                <TimeToCard
                    message="Estimated Time for the order is"
                    duration={`${prepTimeConfig.estimatedTimeInMins} minutes`}
                />
            ) : null}

            <CartAction
                label={enablePayLater ? 'Pay Later' : 'Checkout'}
                disabled={
                    Object.keys(orders).length === 0 ||
                    pending ||
                    areOrdersPaused ||
                    (outsideBizHours && !acceptOrderForLater)
                }
                pending={pending}
                onCartSubmit={() => {
                    placeOrder(
                        enablePayLater,
                        context,
                        setPending,
                        toggleShowOrders,
                        thirdPartyConfig
                    );
                }}
            />
        </div>
    );
}

CartFilled.propTypes = {};

export default CartFilled;
