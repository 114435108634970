import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import Caption from './Caption';

const countryCode = '+1'; // TODO: Read from config later
function PhoneNumber({ onChange, message, value, ...others }) {
    return (
        <div style={{ marginBottom: 8 }}>
            <TextField
                placeholder="Phone Number"
                label="Text Updates @"
                name="telephone"
                value={value}
                fullWidth
                required
                margin="normal"
                variant="outlined"
                size="small"
                {...others}
                onChange={onChange}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {countryCode}
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    type: 'tel',
                    pattern: '[0-9]*',
                    maxLength: '14',
                }}
            />
            {message && (
                <div style={{ marginTop: -8 }}>
                    <Caption>{message}</Caption>
                </div>
            )}
        </div>
    );
}

PhoneNumber.propTypes = {};

export default PhoneNumber;
