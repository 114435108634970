/**
 * This is for online orders only
 * User can edit cart, delete items from cart, add items to cart
 * Finally goes to checkout screen.
 */

import React, { useContext } from 'react';
import CartEmpty from './CartEmpty';
import CartFilled from './CartFilled';
import { CartDialogShadow, OrderStyled } from './Order.styles';
import PayHere from './PayHere';
import Reviews from './Reviews';
import { OrderContent } from './Order.styles';
import { updateLocalStorage } from './utils/placeOrder';
import { OrderContext } from '../context/orderContext';

// State:
//      Data coming from database
//      Data configured by user

// useOrders: getRestauNameAndMenu
//      Read from DB and initial setup
// useOrders: getRestauAndTableId
//      Read from URL params
//      setup the context,
//      follow-up API call
// ConfigDispatcher/ConfigReducer
function Cart(props) {
    const { toggleShowOrders } = props;

    const context = useContext(OrderContext);
    const {
        disableOrdering,
        orders,
        qsrMode,
        tableId,
        pendingPayment,
        disableContaclessPay,
    } = context;

    const showCheckout = !disableOrdering;
    let showReviews = disableOrdering && !pendingPayment;
    let showPayHere = disableOrdering && pendingPayment;
    if (showPayHere && disableContaclessPay) {
        showReviews = true;
        showPayHere = false;
    }

    const closeDialog = () => {
        toggleShowOrders();
        updateLocalStorage(context);
    };

    return (
        // shows up before payment
        <>
            <CartDialogShadow onClick={closeDialog} />
            <OrderStyled>
                <OrderContent>
                    {showCheckout && (
                        <>
                            {Object.keys(orders).length === 0 ? (
                                <CartEmpty
                                    qsrMode={qsrMode}
                                    tableId={tableId}
                                    onAddItems={toggleShowOrders}
                                />
                            ) : (
                                <CartFilled
                                    {...props}
                                    toggleShowOrders={toggleShowOrders}
                                />
                            )}
                        </>
                    )}
                    {showReviews && (
                        <Reviews toggleShowOrders={toggleShowOrders} />
                    )}
                    {showPayHere && <PayHere />}
                </OrderContent>
            </OrderStyled>
        </>
    );
}

Cart.propTypes = {};

export default Cart;
