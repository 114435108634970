const phoneRegex = new RegExp(
    '^[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}$'
);

export function validatePhoneNumberPattern(num) {
    return phoneRegex.test(num);
}

export const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return 'Enter your phone number to receive a text when your order is ready';
    } else {
        const validPhone = validatePhoneNumberPattern(phoneNumber);
        if (!validPhone) {
            return 'Please enter a valid phone number';
        }
    }
    return '';
};

export const validateName = (orderUnderName) => {
    if (!orderUnderName) {
        return 'Please enter name / nickname / initials for the order.';
    }
    return '';
};

export const validatePhoneNumberAndName = ({
    promotionMessages,
    orderUnderName,
    phoneNumber,
}) => {
    //debugger;
    if (promotionMessages.phone_and_name_not_required) {
        return '';
    }

    return validatePhoneNumber(phoneNumber) || validateName(orderUnderName);
};

export const validateOutsideBizHours = ({
    outsideBizHours,
    isOrderAheadEnabled,
    scheduledForLater,
    orderType,
    deliveryScheduledForLater,
    isDeliveryAheadEnabled,
}) => {
    if (
        orderType === 'Delivery' &&
        outsideBizHours &&
        isDeliveryAheadEnabled &&
        !deliveryScheduledForLater
    ) {
        return 'Please select delivery later as store is currently closed.';
    } else if (
        orderType !== 'Delivery' &&
        outsideBizHours &&
        isOrderAheadEnabled &&
        !scheduledForLater
    ) {
        return 'Please select pickup later as store is currently closed.';
    }
    return '';
};

export const validateScheduleOrdersOnly = ({
    acceptScheduleOrdersOnly,
    scheduledForLater,
}) => {
    if (acceptScheduleOrdersOnly) {
        if (!scheduledForLater) {
            return 'Only scheduled orders are accepted. Please select data and time.';
        }
    }
    return '';
};

export const validateSchedule = ({
    scheduledForLater,
    scheduledDate,
    scheduledTime,
    orderType,
    deliveryScheduledForLater,
    deliveryScheduledDate,
    deliveryScheduledTime,
    isDeliveryAheadEnabled,
    deliveryTimings,
    isDeliveryDisabled,
}) => {
    if (
        orderType === 'Delivery' &&
        isDeliveryAheadEnabled &&
        !deliveryScheduledForLater &&
        isDeliveryDisabled(deliveryTimings)
    ) {
        return 'Please schedule the delivery orders';
    }

    if (
        orderType === 'Delivery' &&
        deliveryScheduledForLater &&
        isDeliveryAheadEnabled
    ) {
        if (!deliveryScheduledDate) {
            return 'Please select delivery schedule date and time';
        }
        if (!deliveryScheduledTime || deliveryScheduledTime.trim() === '') {
            return 'Please select delivery schedule time';
        }
    } else if (scheduledForLater) {
        if (!scheduledDate) {
            return 'Please select Schedule date and time';
        }
        if (!scheduledTime || scheduledTime.trim() === '') {
            return 'Please select Schedule time';
        }
    }
    return '';
};

export const validateDeliveryDetails = ({
    orderType,
    deliveryAddress,
    totalInCart,
    locationConfig,
    isDeliveryAheadEnabled,
    deliveryTimings,
    isDeliveryDisabled,
    setDeliveryAddress,
    isDeliveryAddressSelected,
}) => {
    if (orderType === 'Delivery') {
        if (
            orderType === 'Delivery' &&
            !isDeliveryAheadEnabled &&
            isDeliveryDisabled(deliveryTimings)
        ) {
            return 'Sorry, We are not taking delivery orders now';
        }
        if (!isDeliveryAddressSelected) {
            setDeliveryAddress('');
            return 'Please select a avalid delivery address';
        }
        if (!deliveryAddress) {
            return 'Please select a valid delivery address';
        }

        if (
            locationConfig &&
            locationConfig.minDeliveryAmt &&
            totalInCart < locationConfig.minDeliveryAmt
        ) {
            return (
                ' For Delivery we need minimum order $' +
                locationConfig.minDeliveryAmt / 100
            );
        }
    }

    return '';
};

export const validateThirdPartyPayment = ({
    cardNumber,
    expiry,
    cvc,
    zip,
    thirdPartyConfig,
    payAtStore,
}) => {
    if (!thirdPartyConfig) {
        return '';
    } else if (thirdPartyConfig.pay_at_restaurant && payAtStore) {
        return '';
    } else if (thirdPartyConfig.integration_type === 'FULL' && !payAtStore) {
        if (!cardNumber || !expiry || !cvc || !zip) {
            return 'Please enter a valid credit card details';
        }
    }
    return '';
};

/**
 * VALIDATIONS
 *  - delivery
 *  - name
 *  - outside business hours
 *  - phoneNumber
 *  - schedule
 */

const validateOrder = (state) => {
    return (
        validateDeliveryDetails(state) ||
        validatePhoneNumberAndName(state) ||
        validateOutsideBizHours(state) ||
        validateSchedule(state) ||
        validateThirdPartyPayment(state) ||
        validateScheduleOrdersOnly(state)
    );
};

export default validateOrder;
