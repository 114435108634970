import { Divider } from '@material-ui/core';
import React, { useContext } from 'react';
import { OrderContext } from '../../context/orderContext';
import { formatPrice } from '../../Data/FoodData';
import {
    getCreditCardFees,
    getDeliveryFeesActual,
    getTax,
    getTip,
} from '../Order.utils';
import OrderTotalEntry from './OrderTotalEntry';

function OrderTotal({ compute }) {
    const context = useContext(OrderContext);
    const {
        salesTax,
        minCreditCardFees: creditCardMinFees,
        minCreditCardAmt: creditCardMinAmount,
        orderType,
        deliveryThreshold,
        deliveryFees,
        tipPercent,

        deliveryFeesPaid,
        minCreditCardFeesPaid,
        tip,
        getOrderSubtotal,
        setTotalInCart,
    } = context;

    // TODO: FOR Review Screen: these should be extracted from order details and not be computed
    const subtotal = getOrderSubtotal();
    const taxLocal = getTax(subtotal, salesTax);
    const tipLocal = compute ? getTip(subtotal, tipPercent) : tip;

    const creditCardFeesLocal = compute
        ? getCreditCardFees(subtotal, creditCardMinAmount, creditCardMinFees)
        : minCreditCardFeesPaid;

    const deliveryFeesActualLocal = compute
        ? getDeliveryFeesActual(
              subtotal,
              orderType,
              deliveryThreshold,
              deliveryFees
          )
        : deliveryFeesPaid;

    const total =
        (subtotal || 0) +
        (taxLocal || 0) +
        (tipLocal || 0) +
        (creditCardFeesLocal || 0) +
        (deliveryFeesActualLocal || 0);

    setTotalInCart(total);

    const cardLabel = creditCardFeesLocal && (
        <>
            <span>Credit Card fees</span>
            <span>{` (order < ${formatPrice(
                creditCardMinAmount / 100
            )})`}</span>
        </>
    );

    const deliveryLabel = (
        <>
            <span>Delivery fees</span>
            <span>
                {deliveryThreshold
                    ? ` (order < ${formatPrice(deliveryThreshold / 100)})`
                    : null}
            </span>
        </>
    );
    return (
        <div style={{ marginTop: 16, marginBottom: 16 }}>
            <OrderTotalEntry
                label="SubTotal"
                value={subtotal}
                variant="body2"
            />
            {creditCardFeesLocal > 0 && (
                <OrderTotalEntry
                    label={cardLabel}
                    value={creditCardFeesLocal}
                    variant="body2"
                />
            )}
            {deliveryFeesActualLocal > 0 && (
                <OrderTotalEntry
                    label={deliveryLabel}
                    value={deliveryFeesActualLocal}
                    variant="body2"
                />
            )}
            <OrderTotalEntry label="Tax" value={taxLocal} variant="body2" />
            <OrderTotalEntry label="Tip" value={tipLocal} variant="body2" />
            <Divider />
            <OrderTotalEntry label="Total" value={total} />
        </div>
    );
}

OrderTotal.propTypes = {};
OrderTotal.defaultProps = {
    compute: true,
};

export default OrderTotal;
