import React from 'react';
import { useSearchParams } from 'react-router-dom';
import StoreRoot from './StoreRoot';

export default function Welcome() {
    const [searchParams] = useSearchParams();
    const restauId = searchParams.get('restauId');
    if (restauId) {
        return <StoreRoot />;
    }

    window.location.replace('./welcome.html');
    return <></>;
}
