import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import React from 'react';
import styled from 'styled-components';

const HorizontalBorders = styled.div`
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-top: 5px;
    margin-bottom: 5px;
`;

function AddMore({ onAddClick }) {
    return (
        <HorizontalBorders>
            <IconButton color="primary" onClick={onAddClick}>
                <i className="fas fa-plus-circle" color="primary"></i>
            </IconButton>
            <Link
                color="inherit"
                component="button"
                variant="h6"
                onClick={onAddClick}
            >
                Add More Items
            </Link>
        </HorizontalBorders>
    );
}

AddMore.propTypes = {};

export default AddMore;
