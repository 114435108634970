import validateOrder from './validateOrder';
import sendOrder from './sendOrder';
import getPayload from './getPayload';
/**
 * UPDATE LOCAL-STORAGE
 *  - deliveryAddress
 *  - orderInstruction
 *  - orderType
 *  - orderUnderName
 *  - phoneNumber
 *  - tip
 */
export const updateLocalStorage = (context) => {
    localStorage.setItem('deliveryAddress', context.deliveryAddress);
    localStorage.setItem('orderInstruction', context.orderInstruction);
    localStorage.setItem('orderType', context.orderType);
    localStorage.setItem('orderUnderName', context.orderUnderName);
    localStorage.setItem('phoneNumber', context.phoneNumber);
    localStorage.setItem('tip', context.tip);
    localStorage.setItem('tipPercent', context.tipPercent);
};

const payLaterUpdates = (data, context) => {
    const {
        setOrderNum,
        setOrderHistoryId,
        setMinCreditCardFeesPaid,
        setDisableOrdering,
    } = context;
    setOrderNum(data['orderNum']);
    setOrderHistoryId(data['orderId']);
    setMinCreditCardFeesPaid(data['minCreditCardFees']);
    setDisableOrdering(true);
    window.alert(
        'Say this to the customer.\n\nYour order number is ' +
            data['orderNum'] +
            '. You will get a SMS Text with a link to make contactless payment and can skip the wait in restaurant. You can use apple or google pay or any credit card. Thank you.'
    );
};

const payNowUpdates = async (data, context) => {
    const { stripePromise, enqueueSnackbar } = context;
    const stripe = await stripePromise;
    const result = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
    });

    //TODO : May be write to DB so we can debug what could have gone wrong.
    if (result.error) {
        console.log(result);
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.

        const errorMessage = result.error.message
            ? `Error: ${result.error.message}. Try checkout again.`
            : 'Error. Try checkout again.';

        enqueueSnackbar(errorMessage, { variant: 'error' });
    }
};

function startNewOrder(context, toggleShowOrders) {
    const {
        restauId,
        tableId,
        qsrMode,
        setOrderHistoryId,
        setOrderNum,
        setTip,
        dispatchOrder,
        setDisableOrdering,
        setPhoneNumber,
        setOrderUnderName,
    } = context;
    dispatchOrder({ type: 'RESET' });
    setOrderHistoryId('');
    setOrderNum('');
    setTip(0);
    localStorage.removeItem('orderNum');
    localStorage.removeItem('orderHistoryId');
    localStorage.removeItem('tip');
    localStorage.removeItem('orders');
    setDisableOrdering(false);
    toggleShowOrders();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    var payLaterToken = params.get('payLaterToken');
    var deliveryToken = params.get('deliveryToken');

    var urlParts = window.location.href.split('/');
    if (urlParts.length >= 3) {
        var newUrl = urlParts[0] + '//' + urlParts[2] + '/' + restauId;
        if (payLaterToken) {
            setOrderUnderName('');
            localStorage.removeItem('orderUnderName');
            setPhoneNumber('');
            localStorage.removeItem('phoneNumber');
            newUrl += '?payLaterToken=' + payLaterToken;
            window.location.href = newUrl;
            return;
        }
        if (deliveryToken) {
            newUrl += '?deliveryToken=' + deliveryToken;
            window.location.href = newUrl;
            return;
        }
        if (qsrMode) {
            newUrl += '?sourceId=MVsUNLj6MGY8nGxlCdV&tableId=' + tableId;
            window.location.href = newUrl;
            return;
        }
        window.history.pushState({}, null, newUrl);
    }
}

const placeOrder = (
    payLater = false,
    context,
    setPending,
    toggleShowOrders,
    thirdPartyConfig
) => {
    const { enqueueSnackbar } = context;
    setPending(true);

    // validate
    const validationMessage = validateOrder(context);
    if (validationMessage) {
        alert(validationMessage);
        setPending(false);
        return validationMessage;
    }

    // update localStorage
    updateLocalStorage(context);
    const payload = getPayload(context);

    // api call
    sendOrder(payLater, payload, thirdPartyConfig)
        .then((data) => {
            if (payLater) {
                payLaterUpdates(data, context);
                startNewOrder(context, toggleShowOrders);
            } else {
                if (
                    thirdPartyConfig &&
                    thirdPartyConfig.integration_type === 'FULL'
                ) {
                    if (data.status === 'SUCCESS') {
                        let qsrURL = '';
                        if (payload.customerType === 'QSR-Dine-In') {
                            qsrURL =
                                '&sourceId=MVsUNLj6MGY8nGxlCdV&tableId=' +
                                payload.tableId;
                        }
                        window.location.href =
                            '/?thirdParty=success&restauId=' +
                            payload.restauId +
                            '&orderHistoryId=' +
                            data.orderId +
                            '&orderUnderName=' +
                            payload.orderUnderName +
                            qsrURL;
                    } else {
                        setPending(false);
                        enqueueSnackbar(
                            'Transaction is not successful,' + data.message,
                            {
                                variant: 'error',
                            }
                        );
                    }
                } else {
                    payNowUpdates(data, context);
                }
            }
        })
        .catch(() => {
            enqueueSnackbar('Transaction is not successful, Please try again', {
                variant: 'error',
            });
        })
        .then(() => {
            setPending(false);
        });
};

export default placeOrder;
