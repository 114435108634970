import styled from 'styled-components';

const DialogContent = styled.div`
    overflow: auto;
    min-height: 20px;
    //padding: 0px 40px;
    //padding-bottom: 20px;
`;
DialogContent.displayName = 'DialogContent';

const StrikePrice = styled.span`
    font-size: 0.875rem;
    color: red;
    margin-left: 5px;
    text-decoration: line-through;
`;
StrikePrice.displayName = 'StrikePrice';

const MenuContent = styled(DialogContent)`
    //padding: 20px;
    height: 100%;
`;
MenuContent.displayName = 'MenuContent';

const FoodGrid = styled.div`
    @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
    }
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        gap: 0px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    padding-bottom: 20px;
`;
FoodGrid.displayName = 'FoodGrid';

const FoodLabel = styled.div`
    ${(props) =>
        props.img !== undefined
            ? `position: absolute;`
            : `display: grid;
 grid-template-columns: 94px auto ;
 grid-gap: 10px;
 `}
`;
FoodLabel.displayName = 'FoodLabel';

const FoodContainer = styled.div`
@media(max-width: 500px) {
padding: 7px 0px;
}
padding: 12px 0px;
border-bottom: 1px solid rgba(39,174,96,0.5);
background-color: ${(props) => (props.oos ? '#c0392b11' : 'white')}

&:hover {
  cursor: pointer;
  background-color: ${(props) => (props.oos ? '#c0392b11' : '#f8f8f8')};
}
`;
FoodContainer.displayName = 'FoodContainer';

const Description = styled.div`
    color: gray;
    font-size: 13px;
    margin-top: 5px;
    padding-left: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;
Description.displayName = 'Description';

const Title = styled.div`
    padding-left: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;
Title.displayName = 'Title';

const ImageDetails = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
`;
ImageDetails.displayName = 'ImageDetails';

const FoodGridRight = styled.div`
    @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        gap: 10px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
`;
FoodGridRight.displayName = 'FoodGridRight';

const FoodLabelRight = styled.div`
    display: grid;
    grid-gap: 10px;

    ${(props) =>
        props.img !== undefined
            ? `grid-template-columns: auto 94px;`
            : `grid-template-columns: auto;`}
`;
FoodLabelRight.displayName = 'FoodLabelRight';

const FoodGrid2 = styled.div`
    @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
    @media (max-width: 500px) {
        grid-template-columns: 1fr;
        gap: 8px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
`;

const FoodLabel2 = styled.div`
    ${(props) =>
        props.img !== undefined
            ? `position: absolute;`
            : `justify-content: space-between;   display: flex;`}
`;
FoodLabel2.displayName = 'FoodLabel2';

const FoodContainer2 = styled.div`
  padding: 7px 0px;
  border-bottom: 1px solid rgba(39,174,96,0.5);
  background-color: ${(props) => (props.oos ? '#c0392b11' : 'white')}

    &:hover {
      cursor: pointer;
      background-color: ${(props) => (props.oos ? '#c0392b11' : '#f8f8f8')};
    }
`;
FoodContainer2.displayName = 'FoodContainer2';

const Description2 = styled.div`
    color: gray;
    font-size: 13px;
    margin-top: 3px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;
Description2.displayName = 'Description2';

export {
    DialogContent,
    StrikePrice,
    MenuContent,
    FoodGrid,
    FoodGrid2,
    FoodGridRight,
    FoodLabel,
    FoodLabel2,
    FoodLabelRight,
    FoodContainer,
    FoodContainer2,
    Description,
    Description2,
    Title,
    ImageDetails,
};
