import React, { useContext } from 'react';
import FormContext from '../FormContext';
import OrderSummary from './OrderSummary';
import FAQ from './FAQ';
import { Grid, Typography } from '@mui/material';

const ViewOrderPage = () => {
    const { loading, strippedInquiryData } = useContext(FormContext);

    return (
        <>
            {loading || Object.keys(strippedInquiryData).length === 0 ? (
                <img
                    src="/spinner.gif"
                    alt="loading"
                    style={{ display: 'block', margin: '0 auto' }}
                />
            ) : (
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 6,
                        mb: 10,
                        paddingLeft: 3,
                        paddingRight: 3,
                    }}
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Thank you for the catering order!
                        </Typography>
                    </Grid>

                    {Object.keys(strippedInquiryData).length !== 0 &&
                        strippedInquiryData.orders && (
                            <Grid item xs={12}>
                                <OrderSummary />
                            </Grid>
                        )}

                    <Grid item container xs={12}>
                        <FAQ />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ViewOrderPage;
