import React, { useContext } from 'react';
import { OrderContext } from '../../context/orderContext';
import Nickname from '../components/Nickname';
import handleKeyPress from '../utils/handleKeyPress';

function OrderUnderName(props) {
    const { orderUnderName, setOrderUnderName } = useContext(OrderContext);

    const handleChange = (event) => {
        setOrderUnderName(event.target.value);
    };

    return (
        <Nickname
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            value={orderUnderName}
            {...props}
        />
    );
}

OrderUnderName.propTypes = {};

export default OrderUnderName;
