import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import Checkbox from './Checkbox';

const OutsideHoursContainer = styled.div`
    margin-top: 10px;
`;

function OutsideHours({ selected, onChange }) {
    return (
        <OutsideHoursContainer>
            <div>
                <Typography color="error">
                    Orders placed now can only be processed when the store opens
                    again
                </Typography>
            </div>
            <div>
                <Checkbox
                    label={<Typography color="error">Accept</Typography>}
                    name="acceptOrderLaterCheckbox"
                    value={selected}
                    onChange={onChange}
                    color="default"
                />
            </div>
        </OutsideHoursContainer>
    );
}

OutsideHours.propTypes = {};

export default OutsideHours;
