import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { OrderContext } from '../../context/orderContext';
import ReviewItem from './ReviewItem';

function ReviewItemList({ showStarRating }) {
    const context = useContext(OrderContext);
    const { orders } = context;

    let showStarRatingForItem = {};
    let orderNames = {};
    Object.keys(orders).forEach((key) => {
        if (orderNames[orders[key].name]) {
            showStarRatingForItem[key] = false;
        } else {
            showStarRatingForItem[key] = true;
            orderNames[orders[key].name] = true;
        }
    }); 

    return (
        <div>
            <Typography>Your Items:</Typography>
            {Object.keys(orders).map((key, index) => {
                return (
                    <ReviewItem
                        key={key}
                        id={key}
                        index={index}
                        showStarRating={showStarRating && showStarRatingForItem[key]}
                    />
                );
            })}
        </div>
    );
}

ReviewItemList.propTypes = {};

export default ReviewItemList;
