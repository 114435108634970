import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { OrderContext } from '../../context/orderContext';
import Checkbox from './Checkbox';
import Dropdown from './Dropdown';

const getPickupTimes = (scheduledDate = '', pickupTimes = []) => {
    if (scheduledDate === '') {
        return [];
    }
    for (let i = 0; i < pickupTimes.length; i++) {
        if (scheduledDate === pickupTimes[i].day) {
            return pickupTimes[i].times;
        }
    }
    return [];
};

const getDateOptions = (list = []) => {
    return list.map((entry) => ({
        label: entry.day,
        value: entry.day,
        ariaLabel: entry.day,
    }));
};

const getTimeOptions = (list = []) => {
    return list.map((value) => ({
        label: value,
        value: value,
        ariaLabel: value,
    }));
};

const dateDefault = {
    label: 'Select Date',
    value: '',
    ariaLabel: 'None',
};

const timeDefault = {
    label: 'Select Time',
    value: '',
    ariaLabel: 'None',
};

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const DateContainer = styled.div`
    flex-grow: 3;
    padding-right: 5px;
`;

const TimeContainer = styled.div`
    flex-grow: 2;
    padding-left: 5px;
`;

function OrderAhead() {
    const {
        pickupTimes,
        scheduledDate,
        setScheduledDate,
        scheduledTime,
        setScheduledTime,
        scheduledForLater,
        setScheduledForLater,
    } = useContext(OrderContext);

    const dateList = [dateDefault, ...getDateOptions(pickupTimes)]; // pickupTimes
    const availableTimes = getPickupTimes(scheduledDate, pickupTimes);
    const timeList = [timeDefault, ...getTimeOptions(availableTimes)]; // getPickupTimes

    function updateScheduleLater(flag) {
        setScheduledForLater(flag);
        if (!flag) {
            setScheduledTime('');
            setScheduledDate('');
        }
    }

    return (
        <div>
            <Checkbox
                label="Pickup Later"
                onChange={updateScheduleLater}
                value={scheduledForLater}
                name="acceptOrderLaterCheckbox"
            />
            {scheduledForLater && (
                <FlexContainer>
                    {/* Show Date Control */}
                    <DateContainer>
                        <Dropdown
                            label="Schedule Date"
                            name="scheduledDate"
                            id="scheduledDate"
                            value={scheduledDate}
                            onChange={setScheduledDate}
                            options={dateList}
                        />
                    </DateContainer>

                    {/* Show Time Control */}
                    <TimeContainer>
                        <Dropdown
                            label="Schedule Time"
                            name="scheduledTime"
                            id="scheduledTime"
                            value={scheduledTime}
                            onChange={setScheduledTime}
                            options={timeList}
                        />
                    </TimeContainer>
                </FlexContainer>
            )}
        </div>
    );
}

OrderAhead.propTypes = {};

export default OrderAhead;
