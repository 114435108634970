import React, { useContext } from 'react';
import FormContext from '../FormContext';
import { Grid, Typography, IconButton, Link } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FAQ from './FAQ';

const SubmissionPage = () => {
    const { loading, formConfig } = useContext(FormContext);

    return (
        <>
            {loading ? (
                <img
                    src="/spinner.gif"
                    alt="loading"
                    style={{ display: 'block', margin: '0 auto' }}
                />
            ) : (
                <Grid
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 6,
                        mb: 10,
                        paddingLeft: 3,
                        paddingRight: 3,
                    }}
                    spacing={2}
                >
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography
                            variant="h5"
                            align="center"
                            sx={{ fontWeight: 'bold' }}
                        >
                            {formConfig.submission_header
                                ? formConfig.submission_header
                                : 'Thank you! Catering inquiry submission is successful.'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography align="justify">
                            {formConfig.submission_body
                                ? formConfig.submission_body
                                : 'We will reach back to you as soon as possible.'}
                        </Typography>
                    </Grid>
                    <br />
                    <Grid
                        item
                        container
                        alignItems="center"
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                        {formConfig.catering_menu_body && (
                            <Grid item>
                                <Typography variant="body1" align="center">
                                    {formConfig.catering_menu_body}
                                </Typography>
                            </Grid>
                        )}
                        {formConfig.catering_menu_body &&
                            formConfig.catering_menu_link && (
                                <Grid item>
                                    <IconButton
                                        href={formConfig.catering_menu_link}
                                        sx={{ padding: 0.2 }}
                                    >
                                        <MenuBookIcon sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Grid>
                            )}
                    </Grid>

                    <Grid
                        item
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        {formConfig.home_page_link && (
                            <Grid item>
                                <Link
                                    href={formConfig.home_page_link}
                                    variant="body1"
                                >
                                    You can visit our home page here
                                </Link>
                            </Grid>
                        )}
                    </Grid>

                    <Grid item container xs={12} md={12} lg={12}>
                        <FAQ />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default SubmissionPage;
