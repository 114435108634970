import { TextField, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { OrderContext } from '../../context/orderContext';
import { formatPrice } from '../../Data/FoodData';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {
    CheckoutPageInputContainer,
    OrderAheadLabel,
    OrderAheadCheckbox,
    VerticalAlignSpan,
    OrderAheadSelect,
    OrderPickupDiv,
} from '../Order.styles';

const DeliveryInfoContainer = styled.div`
    margin: 10px 0 5px;
`;
function DeliveryInfo() {
    const {
        deliveryFees,
        setDeliveryFees,
        deliveryThreshold,
        deliveryAddress,
        setDeliveryAddress,
        getOrderSubtotal,
        locationConfig,
        deliveryAPT,
        setDeliveryAPT,

        deliveryScheduledForLater,
        setDeliveryScheduledForLater,
        deliveryTimes,
        deliveryScheduledDate,
        setDeliveryScheduledDate,
        deliveryScheduledTime,
        setDeliveryScheduledTime,
        isDeliveryAheadEnabled,
        setDeliveryAheadEnabled,
        setDeliveryAddressSelected,
    } = useContext(OrderContext);

    const subTotal = getOrderSubtotal();
    let message = '';
    if (deliveryThreshold && deliveryThreshold > 0) {
        const formattedThreshold = formatPrice(deliveryThreshold / 100);
        const formattedFees = formatPrice(deliveryFees / 100);
        const deliveryIsFree = deliveryThreshold
            ? ` for order > ${formattedThreshold}`
            : '';
        const deliveryIsCharged = deliveryThreshold
            ? ` for order < ${formattedThreshold}`
            : '';
        message =
            !deliveryFees || subTotal > deliveryThreshold
                ? `Free Delivery${deliveryIsFree}.`
                : `${formattedFees} delivery fees will be added ${deliveryIsCharged}`;
    } else {
        const formattedFees = formatPrice(deliveryFees / 100);
        message = 'Delivery fees will be added ' + formattedFees;
    }

    function handleChange(address) {
        setDeliveryAddress(address);
    }

    function distance(lat1, lon1, lat2, lon2, unit) {
        if (lat1 == lat2 && lon1 == lon2) {
            return 0;
        } else {
            var radlat1 = (Math.PI * lat1) / 180;
            var radlat2 = (Math.PI * lat2) / 180;
            var theta = lon1 - lon2;
            var radtheta = (Math.PI * theta) / 180;
            var dist =
                Math.sin(radlat1) * Math.sin(radlat2) +
                Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = (dist * 180) / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit == 'K') {
                dist = dist * 1.609344;
            }
            if (unit == 'N') {
                dist = dist * 0.8684;
            }
            return dist;
        }
    }

    function calculateDistance(result) {
        let miles = distance(
            locationConfig.lat,
            locationConfig.lng,
            result.lat,
            result.lng,
            'K'
        );
        if (miles > locationConfig.maxDistance) {
            setDeliveryAddress('');
            alert(
                "Sorry, We don't deliver more than " +
                    locationConfig.maxDistance +
                    ' miles'
            );
            return;
        }
        for (let fee of locationConfig.fees) {
            if (miles > fee.start && miles < fee.end) {
                setDeliveryFees(fee.fee);
                break;
            }
        }
    }

    function handleSelect(address) {
        setDeliveryAddress(address);
        setDeliveryAddressSelected(false);
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                calculateDistance(latLng);
                setDeliveryAddressSelected(true);
            })
            .catch((error) => console.error('Error', error));
    }

    function updateScheduleLater(flag) {
        setDeliveryScheduledForLater(flag);
        if (!flag) {
            setDeliveryScheduledTime('');
            setDeliveryScheduledDate('');
        }
    }
    function getDeliveryTimes() {
        if (deliveryScheduledDate === '') {
            return [''];
        }
        for (let i = 0; i < deliveryTimes.length; i++) {
            if (deliveryScheduledDate === deliveryTimes[i].day) {
                return deliveryTimes[i].times;
            }
        }
        return [''];
    }

    return (
        <>
            <Typography color="textSecondary">{message}</Typography>
            <PlacesAutocomplete
                value={deliveryAddress}
                onChange={handleChange}
                onSelect={handleSelect}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                }) => (
                    <div>
                        <form autocomplete="off">
                            <TextField
                                label="Delivery Address"
                                name="address"
                                multiline
                                fullWidth
                                required
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                onPaste={(e) => {
                                    e.preventDefault();
                                }}
                                onpaste="return false"
                                minRows="2"
                                placeholder="room #, hotel name, ..."
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? {
                                              backgroundColor: '#fafafa',
                                              cursor: 'pointer',
                                          }
                                        : {
                                              backgroundColor: '#ffffff',
                                              cursor: 'pointer',
                                          };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                    className,
                                                    style,
                                                }
                                            )}
                                        >
                                            <span>
                                                {suggestion.description}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                            <TextField
                                label="APT/SUITE Number"
                                name="apt"
                                multiline
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                minRows="1"
                                placeholder="#APT/SUITE Number"
                                value={deliveryAPT}
                                onChange={(e) => {
                                    setDeliveryAPT(e.target.value);
                                }}
                            />
                        </form>
                    </div>
                )}
            </PlacesAutocomplete>
            {isDeliveryAheadEnabled && (
                <>
                    <CheckoutPageInputContainer>
                        <OrderAheadLabel>
                            <OrderAheadCheckbox
                                type="checkbox"
                                name="acceptOrderLaterCheckbox"
                                value="Accept"
                                defaultChecked={deliveryScheduledForLater}
                                onClick={(event) =>
                                    updateScheduleLater(event.target.checked)
                                }
                            />{' '}
                            <VerticalAlignSpan>
                                Schedule Delivery Later
                            </VerticalAlignSpan>
                        </OrderAheadLabel>
                    </CheckoutPageInputContainer>
                    {deliveryScheduledForLater && (
                        <CheckoutPageInputContainer>
                            <OrderPickupDiv>
                                <OrderAheadSelect
                                    name="scheduledDate"
                                    id="scheduledDate"
                                    value={deliveryScheduledDate}
                                    onChange={(e) =>
                                        setDeliveryScheduledDate(e.target.value)
                                    }
                                >
                                    <option value={''}>{'Select Date'}</option>
                                    {deliveryTimes.map((e, i) => (
                                        <option value={e.day}>{e.day}</option>
                                    ))}
                                </OrderAheadSelect>

                                <OrderAheadSelect
                                    name="scheduledTime"
                                    id="scheduledTime"
                                    value={deliveryScheduledTime}
                                    onChange={(e) =>
                                        setDeliveryScheduledTime(e.target.value)
                                    }
                                >
                                    <option value={''}>{'Select Time'}</option>
                                    {getDeliveryTimes().map((e, i) => (
                                        <option value={e}>{e}</option>
                                    ))}
                                </OrderAheadSelect>
                            </OrderPickupDiv>
                        </CheckoutPageInputContainer>
                    )}
                </>
            )}
        </>
    );
}

DeliveryInfo.propTypes = {};

export default DeliveryInfo;
