import getURLParam from './getURLParam';

export default function () {
    let rid = getURLParam('restauId') || '';
    if (!rid) {
        // represents the case when its a browser refresh - when we were rewriting the href.
        //       return null;

        // Since we stopped rewriting the window location. restauId will always be present.
        // if not present we get the last part of pathname as restauId.
        let urlpath = window.location.pathname;
        // replace the last / if present.
        let parts = urlpath.replace(/\/$/, '').split('/');
        if (parts.length >= 2 && parts[parts.length - 2] === 'chain') {
        } else if (parts.length >= 1) {
            rid = parts[parts.length - 1];
        }
    }
    return rid;
}
