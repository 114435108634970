import React, { useContext } from 'react';
import { OrderContext } from '../../context/orderContext';
import CartItem from './CartItem';

function CartItems() {
    const { orders } = useContext(OrderContext);

    return Object.keys(orders).map((key, index) => {
        return <CartItem key={key} id={key} index={index} />;
    });
}

CartItems.propTypes = {};

export default CartItems;
