import Config from '../../config/Config';
const { default: axios } = require('axios');

// This method is called when customer makes payment for order made with pay later
async function makePayment(context, tipVal, setPending) {
    const {
        stripePromise,
        enqueueSnackbar,
        setTip,
        restauId,
        orderHistoryId,
        restauName,
    } = context;

    setPending(true);
    setTip(tipVal); // TODO: tipVal is not needed, can be used from context.
    localStorage.setItem('tip', tipVal);

    const stripe = await stripePromise;
    console.log('calling makePayment');
    var url = Config.make_payment_url;
    var data;
    try {
        var resp = await axios.post(url, {
            restauId: restauId,
            orderId: orderHistoryId,
            restauName,
            sendText: true,
            tip: tipVal,
        }); // make sendText as checkbox with default selected.
        data = resp.data;
    } catch (error) {
        console.log('Error calling makePayment');
        setPending(false);
        enqueueSnackbar('Transaction is not successful, Please try again.', {
            variant: 'error',
        });
        return;
    }
    console.log('makePayment response', data);

    //TODO: this hack
    setPending(false);
    const result = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
    });

    //TODO : May be write to DB so we can debug what could have gone wrong.
    if (result.error) {
        console.log(result);
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.

        var errmsg = 'Error. Try payment again.';
        if ('message' in result.error) {
            errmsg = 'Error: ' + result.error.message + '. Try payment again.';
        }
        enqueueSnackbar(errmsg, { variant: 'error' });
    }
    setPending(false);
}

export default makePayment;
