import React from 'react';
import styled from 'styled-components';
import { corianderGreen } from '../Styles/colors';
import { Title } from '../Styles/title';

export const CartButton = styled(Title)`
    color: white;
    font-weight: bold;
    border-radius: 50px;
    padding: 10px;
    position: fixed;
    bottom: 15px;
    text-align: center;
    width: 200px;
    left: calc(50% - 100px);
    cursor: pointer;
    background-color: ${corianderGreen};
    ${({ disabled }) =>
        disabled &&
        `
    opactity: .5; 
    background-color: grey; 
    pointer-events: none; 
  `}
`;

export function ViewCart({
    toggleShowOrders,
    orders,
    disableOrdering,
    areOrdersPaused,
}) {
    function onCartClick() {
        toggleShowOrders();
    }

    var msg = 'Place Order';

    if (disableOrdering) {
        msg = 'View Placed Order';
    }

    return Object.keys(orders).length === 0 ? (
        <div />
    ) : (
        <>
            <CartButton onClick={onCartClick} disabled={areOrdersPaused}>
                <span role="img" aria-label="Cart">
                    <i className="fas fa-utensils"></i> &nbsp; {msg}
                </span>
            </CartButton>
        </>
    );
}
