import React, { useContext } from 'react';
import FormContext from '../FormContext';
import FormHeader from './FormHeader';
import Form from './Form';
import FAQ from './FAQ';
import { Grid } from '@mui/material';

const FormPage = () => {
    const { loading } = useContext(FormContext);
    return (
        <>
            {loading ? (
                <img
                    src="/spinner.gif"
                    alt="loading"
                    style={{ display: 'block', margin: '0 auto' }}
                />
            ) : (
                <Grid
                    container
                    spacing={4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 3,
                        paddingLeft: 3,
                        paddingRight: 3,
                        marginBottom: 10,
                    }}
                >
                    <Grid item container xs={12}>
                        <FormHeader />
                    </Grid>

                    <Grid item container xs={12}>
                        <Form />
                    </Grid>

                    <Grid item container xs={12}>
                        <FAQ />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default FormPage;
